import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { Template, Page } from 'common/layout';
import { ContentArea } from 'common/contentArea';
import { ImageComponent, EventData } from 'modules/landingsPages';

const EventPage = ({ data }: EventPageProps) => {
  const layoutData = data.contentfulPageEvent;
  const body = layoutData.body;
  const cover = {image : layoutData.coverImage, title: layoutData.title, contentfulid: ''};

  return (
    <Template
      metaTitle={layoutData.title}
      metaDescription={layoutData.metaDescription}
    >
      <Page hideContactFooter pageColor={layoutData.pageColor}>
        <ImageComponent content={cover} />
        <EventData
          title={layoutData.title}
          ticketLink={layoutData.ticketLink}
          ticketText={layoutData.ticketText}
          organisers={layoutData.organisers}
          date={layoutData.date}
        />
        <ContentArea contentTypes={body} />
      </Page>
    </Template>
  );
};

export default EventPage;

export const query = graphql`
  query EventPage($id: String) {
    contentfulPageEvent(id: {eq: $id}) {
      title
      slug
      metaDescription
      pageColor
      organisers
      date
      ticketText
      ticketLink
      coverImage {
        file {
          url
          fileName
        }
        title
      }
      body {
        __typename
        ...EventPageBodyFragment
      }
    }
  }
`;

export const eventPageBodyFragment = graphql`
  fragment EventPageBodyFragment on Node {
    ...RichText
    ...Video
    ...ImageComponent
    ...Events
  }
`;

type EventPageProps = {
  data: {
    contentfulPageEvent: i.EventPage;
  };
};
